// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-boardgames-js": () => import("./../src/pages/boardgames.js" /* webpackChunkName: "component---src-pages-boardgames-js" */),
  "component---src-pages-boardgamestable-js": () => import("./../src/pages/boardgamestable.js" /* webpackChunkName: "component---src-pages-boardgamestable-js" */),
  "component---src-pages-dev-js": () => import("./../src/pages/dev.js" /* webpackChunkName: "component---src-pages-dev-js" */),
  "component---src-pages-drawings-js": () => import("./../src/pages/drawings.js" /* webpackChunkName: "component---src-pages-drawings-js" */),
  "component---src-pages-gigs-js": () => import("./../src/pages/gigs.js" /* webpackChunkName: "component---src-pages-gigs-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

