import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    languages: {
      csharp: {
        primary: '#cda4c9',
        light: '#e6d2e4',
        lightest: '#f3e9f2',
      },
      powershell: {
        primary: '#81bbde',
        light: '#c0ddef',
        lightest: '#e0eef7',
      },
      python: {
        primary: '#d3dbb0',
        light: '#e9edd8',
        lightest: '#f4f6ec',
      },
      ruby: {
        primary: '#d8887f',
        light: '#ecc4bf',
        lightest: '#f6e2df',
      },
      javascript: {
        primary: '#f8eda7',
        light: '#fcf6d3',
        lightest: '#fefbe9',
      }
    }
  },
});

export default theme;